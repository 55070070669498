package cz.aipsafe.safe.frontend.about

import cz.aipsafe.safe.frontend.util.Component
import cz.aipsafe.safe.frontend.util.generator
import org.w3c.dom.Document

class AboutBox(document: Document, resources: AboutResources): Component() {

    private val supportLink = SupportLink(document, resources)

    override val element = document.generator.div {
        +supportLink.element
    }
}