package cz.aipsafe.safe.frontend.about

import cz.aipsafe.safe.frontend.lang.Language
import cz.aipsafe.safe.frontend.lang.ResourcesBase

class AboutResources(language: Language) : ResourcesBase(language){

    val support = resource(
        en="Customer support system",
        cs="Systém zákaznické podpory")
    val supportLink = resource("https://support.aipsafe.cz")
    val supportText = resource(
        en="(requires login information)",
        cs="(vyžaduje přihlašovací údaje)")
    val www = resource("AiP Safe s.r.o.")
    val wwwLink = resource(
        en="https://www.aipsafe.cz/en/support",
        cs="https://www.aipsafe.cz/cs/support")
}

