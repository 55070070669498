import cz.aipsafe.safe.frontend.about.AboutBox
import cz.aipsafe.safe.frontend.about.AboutResources
import cz.aipsafe.safe.frontend.lang.Language
import org.w3c.dom.asList
import kotlin.browser.document
import kotlin.browser.window

external val globalLanguage: String?

private fun currentLanguage(): Language {
    val safeLanguage = globalLanguage
    val browserLanguage = window.navigator.language
    return if (safeLanguage != null) {
        when (safeLanguage) {
            "cs" -> Language.CS
            else -> Language.EN
        }
    } else {
        when (browserLanguage) {
            "cs-CZ" -> Language.CS
            else -> Language.EN
        }
    }
}

fun aboutBox() {
    val parentElements = document.getElementsByClassName("content")
    val parent = parentElements.asList().last()
    val resources = AboutResources(currentLanguage())
    val aboutBox = AboutBox(parent.ownerDocument!!, resources)
    parent.appendChild(aboutBox.element)
}