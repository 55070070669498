package cz.aipsafe.safe.frontend.lang

open class ResourcesBase(private val language: Language) {

    protected fun <T> resource(en: T, cs: T? = null, sk: T? = null): T {
        return when(language) {
            Language.CS -> cs ?: en
            Language.SK -> sk ?: en
            Language.EN -> en
        }
    }
}
