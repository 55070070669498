package cz.aipsafe.safe.frontend.util

import kotlinx.html.dom.create
import kotlinx.html.js.*
import org.w3c.dom.*
import org.w3c.dom.events.Event
import kotlin.browser.document
import kotlin.dom.addClass
import kotlin.dom.appendText
import kotlin.dom.clear

var lastId = 0L

/** Generate some elements and append them to current element. */
fun <T: HTMLElement> T.generate (block: HTMLDOMGenerator<T>.()->Unit) {
    HTMLDOMGenerator(this.ownerDocument?: document, this).apply(block)
}

/** Remove all sub elements from current element and generate new subelements. */
fun <T: HTMLElement> T.regenerate (block: HTMLDOMGenerator<T>.()->Unit) {
    this.clear()
    HTMLDOMGenerator(this.ownerDocument?: document, this).apply(block)
}

/** Generator of html elements. */
val Document.generator: HTMLDOMGenerator<HTMLElement> get() {
    return HTMLDOMGenerator(this)
}

/** Return new unique id for this element. */
fun Document.nextId(): String {
    return (++lastId).toString()
}

/** Generator of html elements. */
class HTMLDOMGenerator<out T: HTMLElement>(val document: Document, val forElement: T? = null) {

    /** Current generated element. */
    val el: T get() = forElement!!

    /** Add text to the current element. */
    operator fun String.unaryPlus() {
        el.appendText(this)
    }

    /** Add element as child to the current element. */
    operator fun HTMLElement.unaryPlus() {
        el.appendChild(this)
    }

    private fun <T: HTMLElement> accept(
            el: T,
            block: HTMLDOMGenerator<T>.()->Unit = {}
    ): T {
        HTMLDOMGenerator(document, el).apply(block)
        forElement?.appendChild(el)
        return el
    }

    fun div(classes: String? = null, block: HTMLDOMGenerator<HTMLDivElement>.()->Unit = {}): HTMLDivElement {
        val el = document.create.div()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun span(classes: String? = null, block: HTMLDOMGenerator<HTMLSpanElement>.()->Unit = {}): HTMLSpanElement {
        val el = document.create.span()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun p(classes: String? = null, block: HTMLDOMGenerator<HTMLParagraphElement>.()->Unit = {}): HTMLParagraphElement {
        val el = document.create.p()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h1(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h1()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h2(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h2()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h3(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h3()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h4(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h4()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h5(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h5()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun h6(classes: String? = null, block: HTMLDOMGenerator<HTMLHeadingElement>.()->Unit = {}): HTMLHeadingElement {
        val el = document.create.h6()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun ul(classes: String? = null, block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLElement {
        val el = document.create.ul()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun ol(classes: String? = null, block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLElement {
        val el = document.create.ol()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun li(classes: String? = null, block: HTMLDOMGenerator<HTMLLIElement>.()->Unit = {}): HTMLLIElement {
        val el = document.create.li()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun hr(block: HTMLDOMGenerator<HTMLHRElement>.()->Unit = {}): HTMLHRElement {
        return accept(document.create.hr(), block)
    }

    fun b(block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLElement {
        return accept(document.create.b(), block)
    }

    fun i(block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLElement {
        return accept(document.create.i(), block)
    }

    fun pre(block: HTMLDOMGenerator<HTMLPreElement>.()->Unit = {}): HTMLPreElement {
        return accept(document.create.pre(), block)
    }

    fun table(classes: String? = null, block: HTMLDOMGenerator<HTMLTableElement>.()->Unit = {}): HTMLTableElement {
        val el = document.create.table()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun tr(block: HTMLDOMGenerator<HTMLTableRowElement>.()->Unit = {}): HTMLTableRowElement {
        return accept(document.create.tr(), block)
    }

    fun th(block: HTMLDOMGenerator<HTMLTableCellElement>.()->Unit = {}): HTMLTableCellElement {
        return accept(document.create.th(), block)
    }

    fun td(block: HTMLDOMGenerator<HTMLTableCellElement>.()->Unit = {}): HTMLTableCellElement {
        return accept(document.create.td(), block)
    }

    fun thead(block: HTMLDOMGenerator<HTMLTableSectionElement>.()->Unit = {}): HTMLTableSectionElement {
        return accept(document.create.thead(), block)
    }

    fun tbody(block: HTMLDOMGenerator<HTMLTableSectionElement>.()->Unit = {}): HTMLTableSectionElement {
        return accept(document.create.tbody(), block)
    }

    fun a(classes: String? = null,
          href: String? = null,
          target: String? = null,
          onclick: ((Event)->dynamic)? = null,
          block: HTMLDOMGenerator<HTMLAnchorElement>.()->Unit = {}): HTMLAnchorElement {
        val el = document.create.a()
        if (classes != null) el.addClass(classes)
        if (href != null) el.href = href
        if (target != null) el.target = target
        if (onclick != null) el.onclick = onclick
        return accept(el, block)
    }

    fun br(block: HTMLDOMGenerator<HTMLBRElement>.()->Unit = {}): HTMLBRElement {
        val el = document.create.br()
        return accept(el, block)
    }

    fun img(classes: String? = null,
            src: String? = null,
            alt: String? = null,
            width: Int? = null,
            height: Int? = null,
            block: HTMLDOMGenerator<HTMLImageElement>.()->Unit = {}): HTMLImageElement {
        val el = document.create.img()
        if (classes != null) el.addClass(classes)
        if (src != null) el.src = src
        if (alt != null) el.alt = alt
        if (width != null) el.width = width
        if (height != null) el.height = height
        return accept(el, block)
    }

    fun form(classes: String? = null, block: HTMLDOMGenerator<HTMLFormElement>.()->Unit = {}): HTMLFormElement {
        val el = document.create.form()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun fieldSet(block: HTMLDOMGenerator<HTMLFieldSetElement>.()->Unit = {}): HTMLFieldSetElement {
        return accept(document.create.fieldSet(), block)
    }

    fun input(classes: String? = null, type: String? = null, placeHolder: String? = null, id: String? = null, block: HTMLDOMGenerator<HTMLInputElement>.()->Unit = {}): HTMLInputElement {
        val el = document.create.input()
        if (classes != null) el.addClass(classes)
        if (type != null) el.type = type
        if (placeHolder != null) el.placeholder = placeHolder
        if (id != null) el.id = id
        return accept(el, block)
    }

    fun label(classes: String? = null, htmlFor: String? = null, block: HTMLDOMGenerator<HTMLLabelElement>.()->Unit = {}): HTMLLabelElement {
        val el = document.create.label()
        if (classes != null) el.addClass(classes)
        if (htmlFor != null) el.htmlFor = htmlFor
        return accept(el, block)
    }

    fun button(classes: String? = null, type: String? = null, block: HTMLDOMGenerator<HTMLButtonElement>.()->Unit = {}): HTMLButtonElement {
        val el = document.create.button()
        if (classes != null) el.addClass(classes)
        if (type != null) el.type = type
        return accept(el, block)
    }

    fun legend(block: HTMLDOMGenerator<HTMLLegendElement>.()->Unit = {}): HTMLLegendElement {
        return accept(document.create.legend(), block)
    }

    fun nav(classes: String? = null, block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLElement {
        val el = document.create.nav()
        if (classes != null) el.addClass(classes)
        return accept(el, block)
    }

    fun style(block: HTMLDOMGenerator<HTMLElement>.()->Unit = {}): HTMLStyleElement {
        val el = document.create.style {}
        return accept(el, block)
    }
}
