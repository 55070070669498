package cz.aipsafe.safe.frontend.about

import cz.aipsafe.safe.frontend.util.Component
import cz.aipsafe.safe.frontend.util.generator
import org.w3c.dom.Document
import kotlin.browser.window

class SupportLink(document: Document, resources: AboutResources): Component() {

    override val element = document.generator.div {
        a {
            el.href="#"
            el.onclick = {
                window.open(resources.supportLink)
            }
            b {
                +resources.support
            }
        }
        +" "
        i {
            +resources.supportText
        }
        +" - "
        a {
            el.href="#"
            el.onclick = {
                window.open(resources.wwwLink)
            }
            b {
                +resources.www
            }
        }
    }
}
